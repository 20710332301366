<template>
  <div class="solutionIndex page_body_con">
    <div class="nav">
      <div class="page_location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">
            <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/solution' }"
            >解决方案</el-breadcrumb-item
          >
          <el-breadcrumb-item>智能养殖</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="margin-tb-5">
      <el-row>
        <el-col :span="6" class="left">
          <div class="page_title_name">
            <h3>智慧农业</h3>
            <span> Smart agriculture </span>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="desc">
            智慧大棚是利用高精度传感器对温室大棚环境进行监测采集并上传到云端，用户可以通过云平台实现对作物生长环境进行实时监测和预警，为科学生产种植提供数据依据；同时利用智能控制系统对温室内部设备进行自动化改造，通过云平台实现对温室内部大棚的远程控制和系统自动化联动控制，大幅度提高农业生产效率，提高产品质量和温室大棚的利用率；建立水肥一体化系统借助云平台能够实现对大棚进行精细化施肥灌溉；在大棚安装高清摄像机实现对到大棚进行实时监控，能够提高管理人员的效率同时能够对大棚物资进行管理。
          </div>
        </el-col>
      </el-row>
      <div class="slideshow">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, i) in list"
              :key="i"
              class="swiper-slide"
              @click="bindDetail(item)"
            >
              <!-- 具体内容 -->

              <img :src="item.pic" />
              <div class="slideshowtext">
                <h6>{{ item.title }}</h6>
                <span class="item_desc">{{ item.desc }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev swiper-button-white">
          <i class="el-icon-arrow-left"></i>
        </div>
        <!-- 白色 -->
        <div class="swiper-button-next swiper-button-black">
          <i class="el-icon-arrow-right"></i>
        </div>
        <!-- 黑色 -->
      </div>
      <div class="custom_box">
        <!-- <p></p> -->
        <el-card shadow="always"> 联系我们定制您的业务需求 </el-card>
        <button class="button_yangzhi" @click="bindCustomized">定制</button>
      </div>
    </div>
  </div>
</template>

<script>
// 引入swiper插件的js
import Swiper from "@/tools/swiper.min.js";
import "@/tools/swiper.min.css";

export default {
  data() {
    return {
      type: 5,
      page: 1,
      limit: 100,
      total: 0,
      list: [],
      pictures: [
        // {
        //   advertiseImages: require("@/assets/images/solution/breed1.png"),
        //   text: "海报名称一",
        //   desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        // },
        // {
        //   advertiseImages: require("@/assets/images/solution/breed2.png"),
        //   text: "海报名称二",
        //   desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        // },
        // {
        //   advertiseImages: require("@/assets/images/solution/breed3.png"),
        //   text: "海报名称三",
        //   desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        // },
        // {
        //   advertiseImages: require("@/assets/images/solution/breed4.png"),
        //   text: "海报名称四",
        //   desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        // },
        // {
        //   advertiseImages: require("@/assets/images/solution/breed1.png"),
        //   text: "海报名称五",
        //   desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        // },
        // {
        //   advertiseImages: require("@/assets/images/solution/breed2.png"),
        //   text: "海报名称六",
        //   desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        // },
      ],
    };
  },

  watch: {
    $route: {
      handler: function (route) {
        if (route.path == "/solution/breed/index") {
          this.type = route.query.type == undefined ? 5 : route.query.type;
          this.page = 1;
          this.getSolutionList();
        }
      },
      immediate: true,
    },
  },
  activated() {
    // window.scrollTo(0, 0);
    this.myswiper();
  },
  methods: {
    getSolutionList() {
      this.list = [];
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: this.limit,
          page: this.page,
          type: 2,
          type_id: this.type,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.data.data;
          this.total = res.data.count;
          // console.log(res.data[0].children)
        }
      });
    },
    //点击定制
    bindCustomized() {
      this.$router.push({
        path: "/introduce/contactus",
        query: { type: 1 },
      });
    },
    bindDetail(e) {
      this.$router.push({
        path: "/solution/breed/detail",
        query: {
          id: e.id,
        },
      });
    },
    myswiper: function () {
      var swiper = new Swiper(".swiper-container", {
        loop: true,
        speed: 1000,
        slidesPerView: 4,
        loopedSlides: 4,
        preventClicksPropagation: false, //阻止默认事件
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000, //3秒切换一次
          pauseOnMouseEnter: true, //悬停停止切换
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.slideshow {
  width: 100%;
  overflow: hidden;
  margin-top: 0.63rem;
}
.slideshowtext {
  width: 350px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  padding: 15px 10px;
  background: #53b26d;
  h6 {
    font-weight: bold;
    color: #ffffff;
    font-size: 24px;
    width: 320px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item_desc {
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 控制多行的行数
    -webkit-box-orient: vertical;
  }
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 100%;
  height: 100%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  cursor: pointer;
  transform: scale(0.8);
}
// /*Swiper原样式 */
// .swiper-button-next {
//   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
// }
/*改变了颜色和加粗的样式*/
// .swiper-button-next {
//   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
// }
// .swiper-button-prev {
//   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
// }

.swiper-button-next,
.swiper-button-prev {
  margin-top: -92px;
  i {
    font-size: 40px;
    font-weight: bold;
    color: #53b26d;
  }
}
.swiper-button-next {
  right: 8%;
}
.swiper-button-prev {
  left: 8%;
}
.swiper-button-next {
  // background: url("../../../assets/images/icon_right.png");
  background: none;
  width: 50px;
  height: 50px;
}
.swiper-button-prev {
  // background: url("../../../assets/images/icon_left.png");
  background: none;
  width: 50px;
  height: 50px;
}

img {
  width: 350px;
  height: 240px;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(0.8);
}
.custom_box {
  height: 48px;
  display: flex;
  // margin-right: 60px;
  justify-content: flex-end;
  margin: 30px 15px 60px 0;
  padding-bottom: 90px;
  .el-card {
    width: 420px;
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 800;
    color: #595959;
  }
  .button_yiliao {
    width: 120px;
    height: 60px;
    background: #006acd;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
  .button_shengxian {
    width: 120px;
    height: 60px;
    background: #ffc700;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
  .button_yangzhi {
    width: 120px;
    height: 60px;
    background: #53b26d;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
}
.solutionIndex {
  height: auto;
  // background: #f5f5f5;
  // padding:30px 0;
  // background: url("../../assets/images/service/Rectangle.png") 100% 100%;
}
.nav {
  padding-top: 120px;
  padding-bottom: 30px;
}
.desc {
  padding: 5px 0;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #575757;
  line-height: 28px;
  letter-spacing: 1px;
  text-indent: 40px;
}
.el-carousel__item.el-carousel__item--card.is-in-stage {
  text-align: center;
}
</style>